.exHeader {
  background-color: #F8F8F8;
  height: 50px;
  line-height: 50px;
}

.headerLogo {
  display: flex;
  align-items: center;
}

.headerTitle {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.85);
}

.userNameWrapper {
  text-align: end;
}
@border-radius-base: 24px;@btn-disable-bg: #E5EDFB;@btn-height-base: 34px;@input-height-base: 34px;@primary-color: #3662DB;@warning-color: #DE6826;@layout-header-background: #2A2A2A;@layout-trigger-background: #3A3A3A;@layout-body-background: #fefefe;